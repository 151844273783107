import styled from 'styled-components'

export const CategoryPostsContentSection = styled.section`
  padding: ${({ decrasePadding }) => (decrasePadding ? '0  0 8rem 0' : '8rem 0')};

  @media only screen and (max-width: 1024px) {
    padding: 5rem 0;
  }

  @media screen and (max-width: 425px) {
    padding: 2rem 0;
  }
`

export const CategoryPostsFilter = styled.ul`
  display: flex;
  margin: 2rem 0;

  li {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    padding: 1rem 0;
    margin: 0 2rem 0 0;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.vividBlue};
    }
    &.active {
      color: ${({ theme }) => theme.colors.vividBlue};
    }

    &:nth-of-type(2) {
      order: 2;
    }

    &:nth-of-type(3) {
      order: 1;
    }

    &:nth-of-type(4) {
      order: 4;
    }

    &:nth-of-type(5) {
      order: 5;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;

    li {
      margin: 0;
    }
  }
`

export const CategoryName = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow', sans-serif;
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;

  @media screen and (max-width: 425px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
`
export const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 15rem 4.28rem;
  justify-items: stretch;
  margin: 5rem 0 10rem 0;

  .post-element {
    animation: animatePost 0.8s;
  }

  @keyframes animatePost {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .gatsby-image-wrapper {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .big {
    grid-column: span 2;
    height: 400px;
    position: relative;
    cursor: pointer;
  }

  .small {
    grid-column: span 1;
    position: relative;
    height: 300px;
    margin-bottom: 5rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12rem 4.28rem;

    .big,
    .small {
      grid-column: span 1;
      height: 400px;
    }
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15rem 4.28rem;

    .big,
    .small {
      grid-column: span 1;
      height: 300px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 425px) {
    .small {
      margin-bottom: 1rem;
    }

    .big {
      margin-bottom: 3rem;
    }
  }
`
