import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { CategoryPostsIntroWrapper, Shadow, Row, Column, Title, Subtitle, ImageContainer } from './styles'
import Container from '../../UI/Container'
import { LocaleConsumer } from '../../Layout'

const CategoryPostsIntro = ({ category }) => {
  const { name, slug } = category
  const {
    file: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(graphql`
    fragment CustomerSuccessImage on File {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      file(relativePath: { eq: "images/customer-sucess.png" }) {
        ...CustomerSuccessImage
      }
    }
  `)

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <CategoryPostsIntroWrapper isCustomer={slug === 'customer-success' || slug === 'price-korisnika'}>
          <Container>
            {(slug === 'customer-success' || slug === 'price-korisnika') && <Shadow />}
            <Row decraseHeight={slug !== 'customer-success' && slug !== 'price-korisnika'}>
              <Column>
                {slug === 'customer-success' || slug === 'price-korisnika' ? (
                  <Subtitle>{i18n.customerSuccessSubtitle}</Subtitle>
                ) : (
                  <Subtitle>{i18n.category}</Subtitle>
                )}

                {(slug === 'customer-success' || slug === 'price-korisnika') && (
                  <Title>{i18n.customerSuccessTitle}</Title>
                )}
              </Column>
              {(slug === 'customer-success' || slug === 'price-korisnika') && (
                <Column>
                  <ImageContainer>
                    <Img fluid={fluid} />
                  </ImageContainer>
                </Column>
              )}
            </Row>
          </Container>
        </CategoryPostsIntroWrapper>
      )}
    </LocaleConsumer>
  )
}

export default CategoryPostsIntro
