import React, { useState, useContext } from 'react'

import { CategoryPostsContentSection, CategoryPostsFilter, CategoryName, PostsGrid } from './styles'

import Container from '../../UI/Container'
import PostList from './PostsList'
import LoadMoreButton from './LoadMoreButton'
import { LanguageContext } from '../../Layout'

const CategoryPostsContent = ({ posts, childrenCat, category, imgPlaceholder }) => {
  const { i18n } = useContext(LanguageContext)
  const [activeCategory, setActiveCategory] = useState(i18n.all)
  const [count, setCount] = useState(9)
  const { slug: categorySlug, name } = category

  const ChildrenCategoryList = ({ categories }) => {
    return categories.map(({ node: { name, id } }) => (
      <li
        onClick={() => setActiveCategory(name)}
        key={`${name}-${id}`}
        className={activeCategory === name ? 'active' : null}
      >
        {name}
      </li>
    ))
  }
  const filteredPosts =
    categorySlug === 'customer-success' || categorySlug === 'price-korisnika'
      ? posts.filter(
          (post) =>
            post.node.categories.find((c) => {
              if (c.slug !== ('price-korisnika' || 'customer-success')) return activeCategory === c.name
            }) || activeCategory === i18n.all
        )
      : posts

  return (
    <CategoryPostsContentSection
      decrasePadding={categorySlug !== 'customer-success' || categorySlug !== 'price-korisnika'}
    >
      <Container>
        {categorySlug === 'customer-success' || categorySlug === 'price-korisnika' ? (
          <CategoryPostsFilter>
            <li onClick={() => setActiveCategory(i18n.all)} className={activeCategory === i18n.all ? 'active' : null}>
              {i18n.all}
            </li>
            <ChildrenCategoryList categories={childrenCat} />
          </CategoryPostsFilter>
        ) : (
          <CategoryName>{name}</CategoryName>
        )}

        <PostsGrid>
          <PostList
            posts={filteredPosts}
            count={count}
            activeCategory={activeCategory}
            categorySlug={categorySlug}
            imgPlaceholder={imgPlaceholder}
          />
        </PostsGrid>
        {count < filteredPosts.length && (
          <LoadMoreButton text={i18n.loadMore} callback={() => setCount((count) => count + 9)} />
        )}
      </Container>
    </CategoryPostsContentSection>
  )
}

export default CategoryPostsContent
