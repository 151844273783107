import styled from 'styled-components'

export const StyledLoadMoreBtn = styled.button`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.5rem;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.lightestGrey};
  border: 1px solid ${({ theme }) => theme.colors.veryLightGray};
  transition: all 0.3s;
  border-radius: 8px;
  display: block;
  margin: 3rem auto;
  padding: 1.42rem 3.57rem;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
