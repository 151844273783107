import styled from 'styled-components'

import clockIcon from '../../../../images/clock.svg'

const getTagColor = ({ type, theme }) => {
  switch (type) {
    case 'hotel':
      return `background-color: ${theme.colors.veryDarkBlue};`
    case 'hostel':
      return `background-color: ${theme.colors.vividBlue};`
    case 'vacation-rentals':
    case 'mali-iznajmljivaci':
      return `background-color: ${theme.colors.moderateGreen};`
    case 'property-managers':
    case 'property-manageri':
      return `background-color: ${theme.colors.softCyan};`
    default:
      return `background-color: ${theme.colors.darkGrey};`
  }
}

export const Tag = styled.span`
  position: absolute;
  left: 0;
  display: inline-block;
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  font-weight: 500;
  line-height: 1.71rem;
  ${getTagColor}
  border-radius: 8px;
  padding: 0.35rem 0.71rem;
  transform: translate(-10%, -50px);

  @media only screen and (max-width: 768px) {
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 45%);
    width: 100%;
    max-width: 190px;
    text-align: center;
  }
`

export const LargeTitle = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: bold;
  line-height: 2.85rem;
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    font-size: 1.71rem;
    line-height: 2.41rem;
    text-align: left;
  }
`

export const SmallTitle = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: bold;
  line-height: initial;
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    line-height: 2.41rem;
    text-align: left;
  }

  @media only screen and (max-width: 425px) {
    font-size: 1.71rem;
    line-height: 2.41rem;
  }
`
export const Author = styled.div`
  display: flex;
  margin-top: 1.2rem;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 1.4rem;
  }
`

export const TimeToRead = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    opacity: 0.6;

    &::before {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin: 0 0.5rem 0 1rem;
    }
  }
`
