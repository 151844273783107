import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CtaTrial from '../components/CTA/Trial'
import CategoryPostsIntro from 'components/Category-posts-components/Category-posts-intro'
import CategoryPostsContent from '../components/Category-posts-components/Category-posts-content'

const MainContent = styled.main`
  background-color: #ffffff;
`

const CategoryPosts = ({ data, pageContext, location }) => {
  const {
    file: {
      childImageSharp: { fluid: placeholder }
    },
    category,
    posts: { edges: posts },
    categorisedPosts: { edges: categorisedPosts },
    children_category: { edges: childrenCat }
  } = data

  const seo = {
    title: category.yoast_meta.yoast_wpseo_title || category.name,
    lang: pageContext.locale,
    image: '',
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        pathname={seo.pathname}
        lang={seo.lang}
        category
        location={location}
        description={category.yoast_meta.yoast_wpseo_metadesc}
      />
      <Header
        mode="dark"
        locale={pageContext.locale}
        hasBlog
        isCustomerSuccess={category.slug}
        posts={posts}
        opacity={0.3}
        location={location}
      />
      <CategoryPostsIntro category={category} postsCount={categorisedPosts.length} />
      <MainContent>
        <CategoryPostsContent
          posts={categorisedPosts}
          childrenCat={childrenCat}
          category={category}
          imgPlaceholder={placeholder}
        />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default CategoryPosts

export const query = graphql`
  query($catID: Int, $locale: String!, $slug: String) {
    file(relativePath: { eq: "featured-placeholder.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    category: wordpressCategory(wordpress_id: { eq: $catID }, lang: { eq: $locale }) {
      name
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
    children_category: allWordpressCategory(filter: { lang: { eq: $locale }, wordpress_parent: { nin: [0] } }) {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    categorisedPosts: allWordpressPost(
      filter: { lang: { eq: $locale }, categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          excerpt
          content
          slug
          lang
          date
          wordpress_id
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            author_name
            name_of_property
          }
          categories {
            lang
            name
            slug
            wordpress_id
          }
        }
      }
    }

    posts: allWordpressPost(sort: { fields: lang, order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          title
          slug
          lang
          excerpt
          date
        }
      }
    }
  }
`
