import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { LargeTitle, SmallTitle, Tag, Author, TimeToRead } from './styles'
import { timeToRead } from '../../../../helper'

const PostList = ({ posts, count, categorySlug, imgPlaceholder }) => {
  if (count) posts = posts.slice(0, count)
  return (
    <>
      {posts.map(
        (
          {
            node: {
              id,
              title,
              slug,
              content,
              featured_media,
              lang,
              categories,
              acf: { name_of_property, author_name }
            }
          },
          index
        ) => {
          const locale = lang === 'en' ? `/${lang}/` : '/'
          const featuredImage = featured_media !== null && featured_media.localFile.childImageSharp.fluid
          if (index < 2) {
            return (
              <div className="big" key={`${id}-${slug}`}>
                <Link to={`${locale}blog/${slug}`}>
                  <Img fluid={featuredImage || imgPlaceholder} alt="" />
                  {(categorySlug === 'customer-success' || categorySlug === 'price-korisnika') && (
                    <Tag
                      type={categories.find(c => c.slug !== 'price-korisnika' && c.slug !== 'customer-success').slug}
                    >
                      {name_of_property || 'Missing Property Name'}
                    </Tag>
                  )}
                  <Author>
                    <span>{author_name ? author_name : 'No Author'}</span>
                    <TimeToRead>
                      <span>{`${timeToRead(content)} read`}</span>
                    </TimeToRead>
                  </Author>
                  <LargeTitle dangerouslySetInnerHTML={{ __html: title }} />
                </Link>
              </div>
            )
          }

          if (index > 1 && index < 6) {
            return (
              <div className="small" key={`${id}-${slug}`}>
                <Link to={`${locale}blog/${slug}`}>
                  <Img fluid={featuredImage || imgPlaceholder} alt="" />
                  {(categorySlug === 'customer-success' || categorySlug === 'price-korisnika') && (
                    <Tag
                      type={categories.find(c => c.slug !== 'price-korisnika' && c.slug !== 'customer-success').slug}
                    >
                      {name_of_property || 'Missing Property Name'}
                    </Tag>
                  )}
                  <Author>
                    <span>{author_name ? author_name : 'No Author'}</span>
                    <TimeToRead>
                      <span>{`${timeToRead(content)} read`}</span>
                    </TimeToRead>
                  </Author>
                  <SmallTitle dangerouslySetInnerHTML={{ __html: title }} />
                </Link>
              </div>
            )
          }
          if (index === 6) {
            return (
              <div className="big post-element" key={`${id}-${slug}`}>
                <Link to={`${locale}blog/${slug}`}>
                  <Img fluid={featuredImage || imgPlaceholder} alt="" />
                  {(categorySlug === 'customer-success' || categorySlug === 'price-korisnika') && (
                    <Tag
                      type={categories.find(c => c.slug !== 'price-korisnika' && c.slug !== 'customer-success').slug}
                    >
                      {name_of_property || 'Missing Property Name'}
                    </Tag>
                  )}
                  <Author>
                    <span>{author_name ? author_name : 'No Author'}</span>
                    <TimeToRead>
                      <span>{`${timeToRead(content)} read`}</span>
                    </TimeToRead>
                  </Author>
                  <LargeTitle dangerouslySetInnerHTML={{ __html: title }} />
                </Link>
              </div>
            )
          }

          return (
            <div className="small post-element" key={`${id}-${slug}`}>
              <Link to={`${locale}blog/${slug}`}>
                <Img fluid={featuredImage || imgPlaceholder} alt="" />
                {(categorySlug === 'customer-success' || categorySlug === 'price-korisnika') && (
                  <Tag type={categories.find(c => c.slug !== 'price-korisnika' && c.slug !== 'customer-success').slug}>
                    {name_of_property || 'Missing Property Name'}
                  </Tag>
                )}
                <Author>
                  <span>{author_name ? author_name : 'No Author'}</span>
                  <TimeToRead>
                    <span>{`${timeToRead(content)} read`}</span>
                  </TimeToRead>
                </Author>
                <SmallTitle dangerouslySetInnerHTML={{ __html: title }} />
              </Link>
            </div>
          )
        }
      )}
    </>
  )
}

export default PostList
